body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "JostBold";
  src: local("JostBold"), url("../src/fonts/Jost-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "JostRegular";
  src: local("JostRegular"),
    url("../src/fonts/Jost-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "JostMedium";
  src: local("JostMedium"),
    url("../src/fonts/Jost-Medium.ttf") format("truetype");
}
